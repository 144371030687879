import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';
import getConfig from 'next/config';
import env from '../../env';

type ContextType = {
  level?: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug' | 'critical';
  extra?: Record<string, unknown> | string;
};

let sentryInitialized = false;

export const initSentry = () => {
  const {
    NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    NODE_ENV,
    CONFIG_BUILD_ID,
    NEXT_PUBLIC_SENTRY_PROJECT
  } = env();

  if (!sentryInitialized && NEXT_PUBLIC_SENTRY_DSN) {
    const config = getConfig();
    const distDir = `${config?.serverRuntimeConfig?.rootDir}/.next`;

    // import * as Sentry from '@sentry/react';
    // import { Integrations } from '@sentry/tracing';

    // Sentry.init({
    //   dsn: 'https://33d75619051b46febd73de6ee0cb629e@o66829.ingest.sentry.io/5423347',
    //   integrations: [new Integrations.BrowserTracing()],
    //   tracesSampleRate: 1.0
    // });

    Sentry.init({
      enabled: NODE_ENV !== 'development',
      environment: `${NEXT_PUBLIC_SENTRY_ENVIRONMENT}`,
      dsn: NEXT_PUBLIC_SENTRY_DSN,
      release: `${NEXT_PUBLIC_SENTRY_PROJECT}@${CONFIG_BUILD_ID}`,
      // Ref: https://github.com/vercel/next.js/blob/canary/examples/with-sentry/pages/_app.js
      integrations: [
        new RewriteFrames({
          iteratee: (frame) => {
            frame.filename = frame?.filename?.replace(distDir, 'app:///_next');
            return frame;
          }
        })
      ]
    });

    sentryInitialized = true;
  }
};

export const captureMessage = (message: string, context: ContextType = {}): void => {
  if (!sentryInitialized) {
    initSentry();
  }

  const { NEXT_PUBLIC_SENTRY_DSN } = env();
  if (NEXT_PUBLIC_SENTRY_DSN && sentryInitialized) {
    Sentry.withScope((scope) => {
      if (context && context.level) {
        scope.setLevel(context.level as Sentry.Severity);
      }
      if (context && context.extra) {
        let extra = typeof context.extra === 'string' ? { message: context.extra } : context.extra;
        if (process.browser && window && window.navigator) {
          const { userAgent, vendor, platform, appVersion, appName, appCodeName } = window.navigator;
          extra = { ...extra, userAgent, vendor, platform, appVersion, appName, appCodeName };
        }
        scope.setExtras(extra);
      } else if (process.browser && window && window.navigator) {
        const { userAgent, vendor, platform, appVersion, appName, appCodeName } = window.navigator;
        scope.setExtras({ userAgent, vendor, platform, appVersion, appName, appCodeName });
      }
      scope.setTag('clientSide', process.browser ? 'true' : 'false');
      Sentry.captureMessage(message);
    });
  }
};

export const captureException = (error: Error, context: ContextType = {}): void => {
  if (!sentryInitialized) {
    initSentry();
  }

  const { NEXT_PUBLIC_SENTRY_DSN } = env();
  if (NEXT_PUBLIC_SENTRY_DSN && sentryInitialized) {
    Sentry.withScope((scope) => {
      if (context && context.level) {
        scope.setLevel(context.level as Sentry.Severity);
      }
      if (context && context.extra) {
        let extra = typeof context.extra === 'string' ? { message: context.extra } : context.extra;
        if (process.browser && window && window.navigator) {
          const { userAgent, vendor, platform, appVersion, appName, appCodeName } = window.navigator;
          extra = { ...extra, userAgent, vendor, platform, appVersion, appName, appCodeName };
        }
        scope.setExtras(extra);
      } else if (process.browser && window && window.navigator) {
        const { userAgent, vendor, platform, appVersion, appName, appCodeName } = window.navigator;
        scope.setExtras({ userAgent, vendor, platform, appVersion, appName, appCodeName });
      }
      scope.setTag('clientSide', process.browser ? 'true' : 'false');
      Sentry.captureException(error);
    });
  }
};
